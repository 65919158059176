<template>
  <div :class="appInfo.deviceType === 3?'app-main active':'app-main'">
    <router-view/>
    <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
                leave-active-class="animate__animated animate__backOutDown">
    <login-register v-if="appInfo.loginRegisterShow" :tk-show="appInfo.loginRegisterShow" @close="appInfo.loginRegisterShow = false"/>
    </transition>
    <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
                leave-active-class="animate__animated animate__backOutDown">
    <sign-in v-if="appInfo.signInShow" :tk-show="appInfo.signInShow" @close="appInfo.signInShow = false"/>
    </transition>
    <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
                leave-active-class="animate__animated animate__backOutDown">
    <public-dialog v-if="appInfo.publicDialogShow" :tk-show="appInfo.publicDialogShow" @close="appInfo.publicDialogShow = false"/>
    </transition>
  </div>
</template>

<script setup>
import {defineComponent, ref, computed, watch, onMounted, onUnmounted, reactive} from "vue";
import loginRegister from '@/components/H5/loginRegister.vue';
import { useStore } from "vuex";
import { commonInfoPost, userChannelGet } from "@/utils/api";
import signIn from '@/components/H5/signIn.vue';
import publicDialog from '@/components/H5/publicDialog.vue';

defineComponent({
  components: {
    loginRegister,
    signIn,
    publicDialog
  }
})

const store = useStore()
const appInfo = reactive({
    deviceType: 0,
    loginRegisterShow: false,
    windowWidth: window.innerWidth,
    signInShow: false,
    publicDialogShow: false
})

// 获取通用数据
const userChannGetFun = (datas) => {
  userChannelGet((res) => {
    const obj = Object.assign(datas, { channerUrl: res.data });
    store.commit("setCommonConfigInfo", obj);
  })
}
const commonInfoPostFun = () => {
    let type;
    let salepro;
    let userAgent = navigator.userAgent.toLowerCase();
    let clinetWidth = document.documentElement.clientWidth;
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
      salepro = clinetWidth / 750;
      if(/(iPhone|iPad|iPod|iOS)/i.test(userAgent)) {
        type = 2
      } else {
        type = 1
      }
    } else {
      type = 3
      if(clinetWidth < 1366 && clinetWidth >= 900) {
        salepro = clinetWidth / 1920;
      } else if(clinetWidth >= 1366 && clinetWidth <= 1920) {
        salepro = clinetWidth / 2560;
      } else if (clinetWidth > 1920) {
        salepro = clinetWidth / 2800;
      } else {
        salepro = clinetWidth / 1366;
      }
    }
    store.commit("setDeviceType", type);
    document.documentElement.style.fontSize = (100 * salepro) + 'px';
    store.commit("setRemMultiple",100 * salepro);
    if(appInfo.deviceType === type) return;
    commonInfoPost({
        deviceType: type  //1 安卓   2 ios   3 pc
    }, (res) => {
        appInfo.deviceType = type;
        userChannGetFun(res.data);
    })
}
// 监听浏览器窗口的变化
const handleResize = () => {
  appInfo.windowWidth = window.innerWidth;
  commonInfoPostFun()
}


const _loginWindowJudge = computed(() => store.state.loginWindowJudge);
watch(_loginWindowJudge, () => {
  appInfo.loginRegisterShow = true;
})
const signInJudge = computed(() => store.state.signIn);
watch(signInJudge, () => {
  appInfo.signInShow = true;
})
const publicDialogJudge = computed(() => store.state.publicDialog);
watch(publicDialogJudge, () => {
  appInfo.publicDialogShow = true;
})

onMounted(() => {
    window.addEventListener('resize', handleResize);
    commonInfoPostFun()
})
onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
})
</script>

<style scoped lang="scss">
.app-main{
  position: relative;
  background-color: $bg-color1;
  &.active{
    width: 500px;
    margin: 0 auto;
    border: .01rem solid #dedede;
    overflow: hidden;
  }
  >div{
    height: 100vh;
    overflow-y: auto;
  }
}
</style>
