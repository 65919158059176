<script setup>

import {userPromotionCheckInInfoPost, userPromotionCheckIn, userInfoGet} from '@/utils/api'
import {defineEmits, defineProps, onMounted, reactive} from "vue";
import {showToast} from "vant";
import store from "@/store";
const props = defineProps({
  tkShow: Boolean,
});

const signInData = reactive({
  display: props.tkShow,
  content: {}
})

const emit = defineEmits(['close']);
const closeTk = () => {
  emit('close')
}
// 签到
const userInfoGetFun = () => {
  userInfoGet((res) => {
    localStorage.setItem('UserInfo',JSON.stringify(res.data));
  })
}

const userPromotionCheckInFun = () => {
  userPromotionCheckIn((res) => {
    showToast(res.msg);
    userInfoGetFun();
    closeTk();
    store.commit('setPublicDialog',store.state.publicDialog + 1 )
  })
}

// 初始化
const dataInit = () => {
  userPromotionCheckInInfoPost((res) => {
    signInData.content = res.data;
  })
}

onMounted(() => {
  dataInit()
})
</script>

<template>
  <div class="s2-main">
    <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
                leave-active-class="animate__animated animate__backOutDown">
    <div class="s2-m-body" v-if="signInData.display">
      <div class="close" @click="closeTk"></div>
      <img class="main" src="@/assets/images/index/signBg.png" alt="">
      <div class="title">{{$t('signIn.s1')}}</div>
      <div class="body">
        <div class="b-t1">{{$t('signIn.s2')}}</div>
        <div class="b-t2">R$:{{signInData.content.amount}}</div>
        <div :class="signInData.content.status == '-1'?'b-t3 disable':'b-t3'" @click="userPromotionCheckInFun">{{$t('signIn.s3')}}</div>
        <div class="b-t4">{{$t('signIn.s4')}}：<span>R$:{{signInData.content.expectAmount}}</span></div>
      </div>
      <div class="foot">
        <div class="f-t1">
          <img class="middle" src="@/assets/images/index/s4.png" alt="">
          <span class="middle">{{$t('signIn.s5')}}</span>
          <img class="middle" src="@/assets/images/index/s3.png" alt="">
        </div>
        <div class="f-t2">{{$t('signIn.s6')}}</div>
      </div>
    </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.s2-main{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 1005;
  .s2-m-body{
    position: absolute;
    left: .22rem;
    top: 12%;
    width: calc(100% - .44rem);
    >.main{
      width: 100%;
      display: block;
    }
    >.close{
      position: absolute;
      right: 5%;
      top: 7%;
      width: 13%;
      padding-bottom: 13%;
    }
    .title{
      position: absolute;
      left: 0;
      top: 22%;
      font-weight: 600;
      font-size: .32rem;
      text-align: center;
      width: 100%;
      color: $font-color1;
    }
    .body{
      position: absolute;
      left: 0;
      top: 35%;
      text-align: center;
      width: 100%;
      .b-t1{
        font-size: .32rem;
        color: $font-color4;
      }
      .b-t2{
        margin: .4rem 0;
        font-size: .56rem;
        font-weight: 700;
        color: $font-color4;
      }
      .b-t3{
        width: 4.6rem;
        line-height: .88rem;
        border-radius: .88rem;
        margin: 0 auto;
        border: 2px solid #FDECBB;
        background: linear-gradient(180deg, #F4BF29 0%, #FF883F 100%);
        box-shadow: 4px 8px 4px 0px rgba(148, 3, 49, 0.08);
        font-size: .32rem;
        color: $font-color1;
        &.disable{
          background: rgba(75, 89, 126, .6);
        }
      }
      .b-t4{
        font-size: .24rem;
        color: #000;
        margin-top: .24rem;
        span{
          color: $font-color4;
          font-size: .24rem;
        }
      }
    }
    .foot{
      position: absolute;
      left: 50%;
      bottom: 6%;
      width: 78%;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      color: $font-color1;
      .f-t1{
        text-align: center;
        margin-bottom: .08rem;
        img{
          width: .56rem;
          height: .12rem;
        }
        span{
          font-size: .28rem;
          margin: 0 .1rem;
          opacity: .6;
        }
      }
      .f-t2{
        font-size: .28rem;
        opacity: .6;
        font-weight: bold;
      }
    }
  }
}
</style>