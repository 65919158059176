export default{
    components: {
        f1: 'Inicio',
        f2: 'Promoção',
        f3: 'Agente',
        f4: 'Depósito',
        f5: 'Perfil',
        f6: 'Login',
        f7: 'Dados vazios',
        b1: "OK",
        b2: "Cancelar",
    },
    index: {
        i1: 'Depósito',
        i2: 'Retirar',
        i3: 'Login',
        i4: 'Registro',
        i5: 'Bem-vindo ao BBJJ.COM, a casa dos melhores jogos! Como parceiro líder mundial da PG SLOTS',
        i6: 'Mais',
        t1: 'Recente',
        t2: 'Bet Records',
        t3: 'Promoção',
        t4: 'Roleta',
        t5: 'VIP',
        t6: 'Peito',
        t7: 'Fundos',
        t8: 'Prêmio',
        t9: 'Agente',
        t10: 'Português',
        t11: 'Baixar App',
        t12: 'Contactar o suporte',
        t13: 'FAQ',
    },
    login: {
        l1: 'Login',
        l2: 'Inserir nome de usuário',
        l3: 'Introduza a palavra-passe',
        l4: 'Login automático',
        l5: 'Esqueceu a senha',
        l6: 'Registro',
        l7: 'Telefone',
        l8: 'Correspondência',
        l9: 'Serviço on-line',
        l10: 'Telefone',
        l11: 'Correspondência',
        l12: 'Número do Celular',
        l13: 'O seu endereço de correio eletrónico',
        l14: 'O seu código de convite',
        l15: 'Introduza a palavra-passe',
        l16: 'Por favor, confirme sua senha',
        l17: 'Confirmo que tenho 18 anos de idade e li os Termos de serviço',
        l18: 'Jogo experimental',
    },
    my: {
        m1: 'Suporte',
        m2: 'Mensagens',
        m3: 'Dados',
        m4: 'conta',
        m5: 'Recuperar o saldo',
        m6: 'Saque',
        m7: 'Depositar',
        m8: 'Juros',
        m9: 'Recharge Real',
        m10: 'Aposta para promoção',
        m11: 'Recuperar o saldo',
        m12: 'Promoção',
        m13: 'Recordes de Apostas',
        m14: 'Relatório',
        m15: 'Gerenciamento de saque',
        m16: 'Convidar',
        m17: 'Dados',
        m18: 'Centro de Segurança',
        m19: 'Música',
        m20: 'FAQ',
        m21: 'Bônus de Sugestão',
        m22: 'Sair',
        w1: 'Recuperar o saldo',
        w2: 'Reais',
        w3: 'Carteira principal atual',
        w4: 'Minha moeda',
        w5: 'Registro',
    },
    team: {
        t1: 'Convidar',
        t2: 'Tutorial de promoção',
        t3: 'Link de Convite',
        t4: 'Comissão',
        t5: 'Desempenho',
        t6: 'Todos os dados',
        t7: 'Finanças dos Subordinados',
        t8: 'Apostas dos Subordinados',
        t9: 'Estatísticas dos Subordinados',
        t10: 'Pedidos de subordinados',
        t11: 'Taxa de Comissão',
        t12: 'drawRecord',
        s1: 'Bônus emitidos nos últimos',
        s2: 'dias',
        s3: 'Bónus de',
        s4: 'Comissão de',
        s5: 'Recebido de',
        s6: 'Pessoas',
        s7: 'Descrição das regras do agente',
        s71: 'Convide usuários para se tornarem membros de sua equipe e você será recompensado com descontos pelas apostas válidas',
        s72: 'Total de apostas efetivas = total de apostas efetivas de todos os jogos',
        s73: 'Aposta efetiva para cada jogo = o valor que você ganha ou perde em um único jogo',
        s74: 'Nível 1: Você receberá um bônus de desconto de 0,2% para apostas efetivas feitas por membros do Nível 1',
        s75: 'Nível 2: Você receberá um bônus de desconto de 0,1% nas apostas efetivas para membros do Nível 2',
        s76: 'Exemplo: 1 participante aposta 1000R$ todos os dias, e 100 inscritos ganham 100R$ em 1 dia com recompensa de R$1R$. ',
        s77: 'Nível 3: Você receberá um bônus de desconto de 0,05% para apostas efetivas feitas por membros do Nível 3',
        s78: 'Exemplo: 1 participante aposta 1000R$ todos os dias e ganha uma recompensa de 0,5R$. 100 inscritos podem ganhar 50R$ em 1 dia. ',
        s8: 'Descrição das regras de recompensa',
        s81: 'Para cada depositante pela primeira vez que você convidar, você receberá um bônus de $ ',
        s82: 'Se houver',
        s83: 'pessoas ou mais, você pode ganhar uma recompensa de',
        s84: 'R$',
        s9: 'Descrição da promoção',
        s91: '1.O que é um subordinado? ',
        s92: 'Na página de compartilhamento, compartilhe seu link de compartilhamento com seus amigos e deixe seus amigos se tornarem seus subordinados. Pessoas promovidas por seus amigos também podem se tornar seus subordinados. Promover mais usuários lhe trará mais comissões. ',
        s93: '2. Como verificar quanto seus subordinados trouxeram para você',
        s94: 'Na página de compartilhamento, compartilhe seu link de compartilhamento com seus amigos e deixe seus amigos se tornarem seus subordinados. Pessoas promovidas por seus amigos também podem se tornar seus subordinados. Promover mais usuários lhe trará mais comissões. ',
        s11: 'Minha identidade',
        s12: 'Histórico',
        s13: 'Receber',
        s14: 'Informações da promoção',
        s15: 'Clique Para Salvar',
        s16: 'Link exclusivo',
        s17: 'Dados da Comissão',
        s18: 'Dados do convert',
        s171: 'Equipe',
        s172: 'Número total de membros da equipe',
        s173: 'Novas pessoas adicionadas hoje',
        s174: 'O número de novas pessoas adicionadas ontem',
        s175: 'Nível',
        s176: 'Número de pessoas',
        s181: 'Aposta válida',
        s182: 'Recarregar',
        s183: 'Retornar ao suporte',
        s184: 'Número de pessoas fazendo o primeiro depósito',
    },
    game: {
        g1: 'Ordem',
        g2: 'História do jogo',
        g3: 'N.º total de boletins de apostas',
        g4: 'Renda total',
        g5: 'Despesas totais',
        g6: 'Hoje',
        g7: 'ontem',
        g8: 'Esta semana',
        g9: 'Valor da aposta',
        z1: 'Roleta',
        z2: 'Contagem de sorteios',
        z3: 'Roda da Fortuna',
        z4: 'Total',
        z5: 'Aviso de premiação',
        z6: 'Recorde de prêmio',
        z7: 'Sorteio',
        z8: 'de roleta',

    },
    opinion: {
        o1: 'Opinião',
        o2: 'Tipo de opinião',
        o3: 'Sugestões de atividades',
        o4: 'Por favor insira um título',
        o5: 'Por favor, insira o conteúdo',
        o6: 'Enviar',
        o7: 'Sugestões de atividades',
        o8: 'Sugestões de recursos',
        o9: 'Sugestões de jogos',
        o10: 'Outras perguntas',
        o11: 'Envio bem-sucedido',
    },
    funds: {
        f1: 'Fundos',
        f2: 'Registro de alteração de conta',
        f3: 'Tudo',
        f4: 'Todos',
        w1: 'Recarregar',
        w2: 'Retirar',
        w3: 'Apostas com dinheiro real',
        w4: 'Apostas Recompensas',
        w5: 'Ganhe dinheiro de verdade',
        w6: 'Plataforma de jogo ajusta valor',
        w7: 'Valor de redução da plataforma de jogo',
        w8: 'Ganhando recompensa',
        w9: 'Recompensa por atividade',
        w10: 'Pagamento de salário',
        w11: 'Desconto de apostas pessoais',
        w12: 'Desconto de apostas de nível inferior',
        w13: 'Convide desconto de recarga pela primeira vez',
        w14: 'Desconto para o número cumulativo de convites',
        w15: 'taxa de manuseio',
        w16: 'Aumentar artificialmente o ouro real',
        w17: 'Reduzir manualmente o ouro verdadeiro',
        w18: 'Aumentar a recompensa manualmente',
        w19: 'Reduzir recompensas manualmente',
        w20: 'Aumentar manualmente o saldo de retirada',
        w21: 'Reduzir manualmente o saldo de retirada',
        w22: 'Reembolso',
        w23: 'Presente de plataforma de jogo',
        w24: 'Cancelar aposta',
        w25: 'cupom',
        w26: 'Tabela de classificação',
        fd1: 'Transação',
        fd2: 'Recarregar',
        fd3: 'Retirar'
    },
    signIn: {
        s1: 'Recompensa de login',
        s2: 'Pode ser coletado hoje',
        s3: 'Entre e receba',
        s4: 'Você pode conseguir se assinar amanhã',
        s5: 'Descrição',
        s6: 'Após completar o login no segundo dia, você pode ganhar um bônus de 2% do volume de apostas do primeiro dia',
        pd1: 'Seguir a conta pública oficial',
        pd2: 'Receba 2 bônus reais',
        pd3: '1. Clique no link de salto da conta pública abaixo e clique para seguir a conta pública',
        pd4: 'Seguir a conta pública',
        pd5: '2. Clique no link oficial de atendimento ao cliente abaixo, envie uma captura de tela da conta pública oficial que você seguiu e seu ID do jogo, e ganhe uma recompensa de 2 reais',
        pd6: 'Atendimento oficial ao cliente'
    },
    exchange: {
        e1: 'Código de resgate',
        e2: 'Confirmar resgate',
        e3: 'Por favor, insira o código de resgate',
        e4: 'Registro de troca',
        e5: 'tempo',
        e6: 'Código',
        e7: 'recompensa'
    }
}