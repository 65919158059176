import API from '@/utils/request'

// 通用信息
export function commonInfoPost (params,callback) {
    API.postRequest("api/index/info", params, function (res) {
        typeof callback === 'function' && callback(res);
    })
}
// 获取频道信息
export function userChannelGet(callback) {
    API.getRequest('api/user/channel',{},function(res) {
        typeof callback === 'function' && callback(res);
    })
}

// 首页游戏列表
export function indexGameListGet (callback) {
    API.getRequest('api/game/game/index',{
        slug:'recommend'
    },function(res) {
       typeof callback === 'function' && callback(res);
    })
}
// 游戏列表
export function gamePageGet (params,callback) {
    API.getRequest('api/game/game/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 游戏记录列表
export function gameLogPageGet(params,callback){
    API.getRequest('api/game/gamelog/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    }, true);
}

// 首页轮播图
export function bannerListGet(callback) {
    API.getRequest('api/index/bannerList',{},function(res) {
        typeof callback === 'function' && callback(res);
    })
}

// 登录
export function loginPost (params,callback) {
    API.postRequest('api/login', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 注册
export function registerPost (params,callback) {
    API.postRequest('api/register', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 获取钱包信息
export function userWalletGet (callback) {
    API.getRequest("api/user/wallet",{},function(res){
        typeof callback === 'function' && callback(res);
    })
}
export function walletLogGet (params,callback) {
    API.getRequest("api/user/wallet/log",params,function(res){
        typeof callback === 'function' && callback(res);
    },true)
}

// 获取游戏信息
export function gameLoginPost (params,callback) {
    API.postRequest("api/game/game/login", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}


// 获取金额信息
export function betInfoGet (callback) {
    API.getRequest("api/user/betInfo", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
// 充值列表
export function payChannelGet (callback) {
    API.getRequest("api/pay/channel/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function payOptionGet (callback) {
    API.getRequest("api/pay/option/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function payOrderPost(params,callback) {
    API.postRequest("api/pay/order", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}

// 提现列表
export function withdrawListPost(callback) {
    API.postRequest("api/withdraw/list", {}, (res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function withdrawOrder(params,callback) {
    API.postRequest("api/withdraw/order", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}

//VIP
//获取VIP等级列表
export function levelListGet (callback) {
    API.getRequest("api/level/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}


// 赌博游戏记录
export function gameBetPageGet (params,callback) {
    API.getRequest("api/game/gamebet/page", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}

// 转盘游戏
export function boxListGet(_tabId,callback) {
    API.getRequest('api/box/list/' + _tabId,{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function promotionSpinTimesGet(callback) {
    API.getRequest('api/user/promotion/spinTimes',{}, (res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userPromotionSpin(callback) {
    API.postRequest('api/user/promotion/spin',{}, (res) => {
        typeof callback === 'function' && callback(res)
    })
}

// 团队分享数据
export function userRewardStaticsPost(callback) {
    API.postRequest('api/user/rewardStatics',{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userPromotionInviteRewardInfo(callback) {
    API.postRequest('api/user/promotion/inviteRewardInfo',{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function indexDistribution(callback) {
    API.postRequest('api/index/distribution',{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userTeamMember(callback) {
    API.getRequest('api/user/team/member',{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userTeamData(callback) {
    API.getRequest('api/user/team/data',{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}

// 签到
export function userPromotionCheckInInfoPost(callback) {
    API.postRequest('api/user/promotion/checkInInfoV2',{},function(res) {
        typeof callback === 'function' && callback(res)
    })
}

export function userPromotionCheckIn(callback) {
    API.postRequest('api/user/promotion/checkInV2',{},function(res) {
        typeof callback === 'function' && callback(res)
    })
}
export function userInfoGet (callback) {
    API.getRequest("api/user/info",{},function(res) {
        typeof callback === 'function' && callback(res)
    })
}
// 帐变记录
export function payOrderListGet(params,callback) {
    API.getRequest("api/pay/order/list", params, (res) => {
        typeof callback === 'function' && callback(res)
    }, true)
}
export function withdrawOrderListGet(params,callback) {
    API.getRequest("api/withdraw/order/list", params, (res) => {
        typeof callback === 'function' && callback(res)
    }, true)
}

// 兑换  api/user/promotion/exchangeLog

export function exchangeLogGet(callback) {
    API.getRequest('api/user/promotion/exchangeLog',{},function(res) {
        typeof callback === 'function' && callback(res)
    },true)
}

export function exchangePost(params,callback) {
    API.postRequest('api/user/promotion/exchange',params,function(res) {
        typeof callback === 'function' && callback(res)
    },true)
}


