import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/permission.js'


// vant 使用
import 'vant/lib/index.css';
import { Swipe, SwipeItem,Icon,Toast,Picker,Popup,Lazyload,DatePicker,Empty,Pagination  } from 'vant';
import 'vant/lib/icon/index.css'
//公共样式
import '@/assets/css/commons.scss'
import 'animate.css';

//多语言
import {createI18n } from 'vue-i18n';
import zh from './assets/lang/zh';
import en from './assets/lang/en';
import por from './assets/lang/por';
const messages = {
    zh: zh,
    por: por,
    en: en
}
const i18n = createI18n({
    locale: 'por', // 当前语言
    fallbackLocale: 'por',
    messages,
    legacy: false
})
// 回退语言  en_US  pt_BR  zh_CN
localStorage.setItem('language', 'pt_BR');

// 九宫格游戏
import VueLuckyCanvas from '@lucky-canvas/vue'

//跑马灯效果
// import scroll  from 'vue-seamless-scroll'
import scroll from 'vue-seamless-scroll/src'

// ElementUI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 检测API时效性
// import { checkApiTime } from '@/utils/validate'
// checkApiTime(() => {
    createApp(App)
        .use(VueLuckyCanvas).use(scroll).use(ElementPlus)
        .use(Swipe).use(SwipeItem).use(Icon).use(Toast).use(Picker).use(Popup).use(Lazyload).use(DatePicker).use(Empty).use(Pagination)
        .use(i18n).use(store).use(router).mount('#app')
// })
